const menu = document.querySelector("#main-navigation");
const hamburger = document.querySelector("#hamburger");
const menuIcon = document.querySelector(".menuIcon");
const closeIcon = document.querySelector(".closeIcon");

// Toggle nav
hamburger.addEventListener("click", function () {
  menu.classList.contains("translate-x-full") ? openNav() : closeNav();
});

// Hide nav on window resise
window.addEventListener("resize", (event) => {
  if (window.innerWidth > 800) {
    closeNav();
  }
});

$(".subnav-toggle").click(function (e) {
  e.preventDefault();
  $(this)
    .siblings(".overlay")
    .addClass("opacity-60")
    .removeClass("pointer-events-none");
  $(this).siblings(".subnav").removeClass("translate-x-full");
});

$(".services-toggle").click(function (e) {
  e.preventDefault();
  openNav();
  $(".subnav-services")
    .siblings(".overlay")
    .addClass("opacity-60")
    .removeClass("pointer-events-none");
  $(".subnav-services").removeClass("translate-x-full");
});

$(".sectors-toggle").click(function (e) {
  e.preventDefault();
  openNav();
  $(".subnav-sectors")
    .siblings(".overlay")
    .addClass("opacity-60")
    .removeClass("pointer-events-none");
  $(".subnav-sectors").removeClass("translate-x-full");
});

$(".overlay").click(function () {
  $(this).removeClass("opacity-60").addClass("pointer-events-none");
  $(this).siblings(".subnav").addClass("translate-x-full");
});

$(".subnav-hide").click(function () {
  $(this)
    .parent()
    .parent()
    .parent()
    .siblings(".overlay")
    .removeClass("opacity-60")
    .addClass("pointer-events-none");
  $(this).parent().parent().parent().addClass("translate-x-full");
});

// Functions
const openNav = () => {
  menu.classList.remove("translate-x-full");
  menu.classList.add("transition", "transform-gpu", "duration-500");
  menuIcon.classList.add("hidden");
  closeIcon.classList.remove("hidden");
  $(".nav-overlay").addClass("opacity-60");
};

const closeNav = () => {
  menu.classList.add("translate-x-full");
  menu.classList.remove("transition", "transform-gpu", "duration-500");
  menuIcon.classList.remove("hidden");
  closeIcon.classList.add("hidden");
  $(".nav-overlay").removeClass("opacity-60");

  $(".subnav").addClass("translate-x-full");
  $(".overlay").removeClass("opacity-60").addClass("pointer-events-none");
};

export { openNav, closeNav };
