import * as nav from "./modules/navigation.js";
import * as carousels from "./modules/carousels.js";
import animateOnScroll from "./modules/observer.js";

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
  },
  false,
);

// Drawers
$(".newsletter-toggle").click(function () {
  $(".newsletter-overlay")
    .addClass("opacity-60")
    .removeClass("pointer-events-none");
  $(".newsletter-form").removeClass("translate-x-full");
});

$(".newsletter-overlay").click(function () {
  $(this).removeClass("opacity-60").addClass("pointer-events-none");
  $(this).siblings(".newsletter-form").addClass("translate-x-full");
});

$(".newsletter-hide").click(function () {
  $(this)
    .parent()
    .parent()
    .siblings(".newsletter-overlay")
    .removeClass("opacity-60")
    .addClass("pointer-events-none");
  $(this).parent().parent().addClass("translate-x-full");
});

$(".bio-toggle").click(function () {
  $(this)
    .parent()
    .siblings(".bio-overlay")
    .addClass("opacity-60")
    .removeClass("pointer-events-none");
  $(this).parent().siblings(".bio").removeClass("translate-x-full");
});

$(".bio-overlay").click(function () {
  $(this).removeClass("opacity-60").addClass("pointer-events-none");
  $(this).siblings(".bio").addClass("translate-x-full");
});

$(".bio-hide").click(function () {
  $(this)
    .parent()
    .parent()
    .siblings(".bio-overlay")
    .removeClass("opacity-60")
    .addClass("pointer-events-none");
  $(this).parent().parent().addClass("translate-x-full");
});
