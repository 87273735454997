import Swiper from "swiper/swiper-bundle.mjs";

const horizontalScroll = new Swiper(".horizontalScroll", {
  slidesPerView: "auto",
  spaceBetween: 16,
  slidesOffsetBefore: 12,
  slidesOffsetAfter: 12,
  freeMode: true,
  mousewheel: {
    forceToAxis: true,
  },
  breakpoints: {
    640: {
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24,
    },
    768: {
      slidesOffsetBefore: 48,
      slidesOffsetAfter: 48,
    },
  },
  on: {
    init: function () {
      $(".horizontalScroll").removeClass("opacity-0");
    },
  },
});

export { horizontalScroll };
